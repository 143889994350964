

   .banner_img {
    // width: 19.2rem;
    // height: 5.01rem;
    width: 100%;
    min-width: 1200px;
   
  
  }
.odd-card {
    background-color: #FAFCFF;
}

.even-card {
    background-color: #fff;
}

.card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.card-canves {
    width: 1161px;
    height: 588px;
    margin: 0 auto;
    display: flex;
    justify-content: center;

}

.card-left {
    
    width: 554px;
    height: 434px;
    margin-bottom: 76px;
    margin-top: 50px;
    margin-right: 200px;
}

.card-right {
    
    width: 554px;
    height: 434px;
    margin-bottom: 76px;
    margin-top: 50px;
}

.card-secTitle {
    font-size: 20px;
    color: #333;
    font-weight: 250;
    font-family: MicrosoftYaHeiLight;
    margin-top: 19px;
}

.card-secSummary {
    font-size: 16px;
    color: #999;
    font-weight: 200;
    font-family: MicrosoftYaHeiLight;
    line-height:25px;
    margin-top: 8px;
}
.card-topic {
    font-size:34px;
    font-family:Lantinghei SC;
    font-weight:500;
    color:rgba(51,51,51,1);
    line-height:49px;
    padding-top: 80px;
    text-align: center;
    
}
.card-title {
    font-size:28px;
    font-family:Lantinghei SC;
    font-weight:450;
    color:rgba(51,51,51,1);
    line-height:49px;
    margin-top: 28px;
    
}




.ant-btn-primary {
    width:140px;
    height:40px;
    border-radius:20px;
    font-size: 16px;
    font-family:Lantinghei SC;
    font-weight:200;
    border: 0 none currentColor;
    
    margin-bottom: 15px;
}

.card-button {
    margin-top: 60px;
    .ant-btn-primary {
        background:linear-gradient(90deg,rgba(77,194,255,1) 0%,rgba(42,152,255,1) 100%);
        
    }
}



.detail-link {
    text-decoration: underline;
    margin-left: 60px;
    font-size:16px;
    font-family:Lantinghei SC;
    font-weight:200;
    color:rgba(52,142,244,1);
}






.poker-table {
    display: flex;
    justify-content: center;
    margin: 60px auto 80px;
}

.poker-1 {
    width: 280px;
    height: 511px;
    display: flex;
    flex-direction: column; 

    margin: 0px 13px 0px;
    border-radius:8px;
    transition:background-color 0.3s ease-in-out , box-shadow 0.3s ease-in-out;

    .ant-btn-primary {
        transition: background-color 0.3s ease-in-out ;
    }

    .ant-btn-link {
        transition: background-color 0.1s ease-in-out ;
    }
  
}

.poker-1-1 {
    background-color: #FFFBF9;
    .ant-btn-primary {
        background-color: #FFECE3;
        color: #FF7121;
    }
}

.poker-1-2 {
    background-color: #F5FBFF;
    .ant-btn-primary {
        background-color: #DEF2FF;
        color: #348EF4;
    }
}

.poker-1-3 {
    background-color: #F3FFFC;
    .ant-btn-primary {
        background-color: #DCFCF4;
        color: #2AB794;
    }
}

.poker-1-4 {
    background-color: #F9F8FF;
    .ant-btn-primary {
        background-color: #ECE9FF;
        color: #8375DC;
    }
}

  

.poker-1:hover {
    background-color:rgba(255,251,249,1);
    box-shadow:0px 7px 32px 0px rgba(184,214,230,0.48);
    
    .ant-btn-primary {
        background:linear-gradient(90deg,rgba(77,194,255,1) 0%,rgba(42,152,255,1) 100%);
        color: #fff;
    }
    
}


.poker-head {
    height:300px;
    width: 280;
    padding-top: 187px;
    background-size: cover;
}

.poker-mall {
    background:url('../../image/weiCommerce/icon_mall.png');
}

.poker-shop {
    background:url('../../image/weiCommerce/icon_shop.png');
}

.poker-server {
    background:url('../../image/weiCommerce/icon_server.png');
}

.poker-brand {
    background:url('../../image/weiCommerce/icon_brand.png');
}

.poker-head-title {
    font-size:24px;
    font-family:Microsoft YaHei;
    font-weight:400;
    color:rgba(255,255,255,1);
    line-height:49px;
    text-align: center;
}

.poker-head-summary {
    font-size:16px;
    font-family:Microsoft YaHei;
    font-weight:300;
    color:rgba(255,255,255,1);
    line-height:49px;
    opacity:0.8;
    text-align: center;
}

.poker-content {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 25px;

    font-size:16px;
    font-family:Microsoft YaHei;
    font-weight:300;
    color:rgba(153,153,153,1);
    line-height:25px;
}

.btn-box {
    display: flex;
    justify-content: center;
    align-items: center;
    
    .ant-btn-primary {
        margin-top: 30px;
    }
}





.poker-2 {
    background-color:#fff;
    width: 280px;
    height: 300px;
    border-radius: 8px;
    display: inline-block;
    padding-top: 27px;
    margin: 0px 13px 0px;
    text-align: center;
    transition: box-shadow 0.3s ease-in-out
    
}

.poker-2:hover {
    box-shadow:0px 7px 32px 0px rgba(184,214,230,0.48);
    

}

.poker2-content {
    text-align: center;
    font-size:24px;
    font-family:Microsoft YaHei;
    font-weight:400;
    color:rgba(102,102,102,1);
    line-height:49px;
    
}

.poker2-icon {
    width: 170px;
    height: 170px;
    margin-bottom: 18px;
    
    
    
}


.card-table {
    background-color: #999;
    text-align: center;
}

.img-box {
    text-align: center;
}

.partner-brand {
    width: 941px;
    height: 248px;
    margin-top: 60px;
    margin-bottom: 80px;
}
