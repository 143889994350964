.home_page {
  overflow: hidden;
       .homepage{
      width: 100%;
      height: 100%;
      background-image: url(../../image/home/homepage.jpg);
      background-size: cover;
        // height: 100%;
      // background-size: cover;
      background-position: center center;
      /* background-position: center; */
      background-repeat: no-repeat;
    }
     .footer_componey_info1 {
       position: absolute;
       bottom: 0;
      
       
       left: 620px;
      width: 1180px;
      height: 50px;

      font-size:16px;
      font-family:Microsoft YaHei;
      font-weight:400;
     color:rgba(51,51,51,1);
      line-height:20px;
    }
    .footer_link1 {
      color:rgba(51,51,51,1);
    }
    .footer_link1:hover {
      color: #348ef4;
    }
  .ant-carousel .slick-slide {
    text-align: center;
    line-height: 160px;
    background: #364d79;
    overflow: hidden;
  }
  
  .ant-carousel .slick-slide h3 {
    color: #fff;
  }
  .indicator {
    position: absolute;
    right: 137px;
    bottom: 426px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    .indicator_dot {
      display: flex;
      flex-direction: column;
      .dot {
        display: inline-block;
        width:6px;
        height:6px;
        background:rgba(255,249,245,1);
        opacity:0.4;
        border-radius:50%;
        margin-bottom: 14px;
      }
      .active {
        background:rgba(255,249,245,1);
        opacity:0.8;
      }
    }
    .indicator_dot_dark {
      display: flex;
      flex-direction: column;
      .dot {
        display: inline-block;
        width:6px;
        height:6px;
        background:#CCCCCC;
        // opacity:0.4;
        border-radius:50%;
        margin-bottom: 14px;
      }
      .active {
        background: #666666;
        // background:rgba(255,249,245,1);
        // opacity:0.8;
      }
    }
 
    .swipe_down_icon {
      margin-top: 21px;
      width: 34px;
      height: 34px;
    }

  }
 
  
  .wcard-container{
     width: 1050px;
     height: 822px;
     margin: 0 auto;
     margin-top: 76px;
     margin-top: 120px;
  
  
   .typeface{
     width:100%;
     height:32px;
     font-size:34px;
     font-family:Lantinghei SC;
     font-weight:600;
     color:rgba(51,51,51,1);
     line-height:86px;
     margin: 0 auto;
     text-align: center;
   }
   .wcard-above{
        display: flex;
        justify-content: center;
        height: 335px;
        top:24px;
        position: relative;
   
  .wcard-section{
    margin-top: 76px;
    position: absolute;
    left: 156px;
  }
   .above-topic{
     width:280px;
     height:51px;
     font-size:20px;
     font-family:Lantinghei SC;
     font-weight:600;
     color:rgba(51,51,51,1);
     line-height:31px; 
   }
   .above-topic1{
     width:162px;
     height:30px;
     font-size:20px;
     font-family:Lantinghei SC;
     font-weight:600;
     color:rgba(51,51,51,1);
     line-height:49px;
   }
   .above-introduce{
     width:261px;
     height:146px;
     font-size:16px;
     font-family:Microsoft YaHei;
     font-weight:400;
     color:rgba(153,153,153,1);
     line-height:28px;
     margin-top: 28px;
    
   }
   .above-picture{
     position: absolute;
     left: 583px;
     top: 33px;
 }
   }
  .wcard-below{
        display: flex;
        justify-content: center;
        height: 335px;
        top: 50px;
        position: relative;
 
  .wcard-section-1{
   margin-top: 165px;
   position: absolute;
    left: 708px;

  }
  .below-picture{
     position: absolute;
     left: 66px;
     top: -35px;
  }
.below-topic{
  width:180px;
  height:20px;
  font-size:20px;
  font-family:Lantinghei SC;
  font-weight:600;
  color:rgba(51,51,51,1);
  line-height:49px;
}
.below-introduce{
  width:245px;
  height:73px;
  font-size:16px;
  font-family:Microsoft YaHei;
  font-weight:400;
  color:rgba(153,153,153,1);
  line-height:28px;
  margin-top: 30px;
}
}
}

.wcard-scene{
   width: 1180px;
   height: 666px;
   margin: 0 auto;
   margin-top: 140px;

}
.scene{
 width:586px;
 height:78px;
 font-size:34px;
 font-family:Lantinghei SC;
 font-weight:600;
 color:rgba(51,51,51,1);
 line-height:49px;
 margin: 0 auto;
}
.operation{
width:137px;
height:64px;
font-size:34px;
font-family:Lantinghei SC;
font-weight:600;
color:rgba(51,51,51,1);
line-height:49px;
margin: 0 auto;
}
.owe{
width:295px;
height:64px;
font-size:34px;
font-family:Lantinghei SC;
font-weight:600;
color:rgba(51,51,51,1);
line-height:49px;
margin: 0 auto;
}
.marketing-list{
  display: flex;
  flex-wrap: wrap;
}

.more-case{
width:140px;
height:48px;
text-align: center;
line-height: 48px;
margin: 0 auto;
background:linear-gradient(90deg,rgba(77,194,255,1) 0%,rgba(42,152,255,1) 100%);
border-radius:24px;
font-size:16px;
font-family:Microsoft YaHei;
font-weight:400;
color:rgba(255,255,255,1);
margin-top: 51px;
cursor: pointer;
}

  
  }