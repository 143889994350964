.wxcode_cmp {
  width: 380px;
  height: 273px;
  position: relative;
  .img_wrap {
    position: relative;
    .img_componey {
      width: 380px;
    }
    .img_title {
      z-index: 99;
      position: absolute;
      top: 0;
      left: 0;
      height:48px;
      line-height: 48px;
      padding: 0 20px;
      background:rgba(0,0,0,1);
      opacity:0.7;
      border-radius:8px 0px 20px 0px;

      font-size:24px;
      font-family:Microsoft YaHei;
      font-weight:400;
      color:rgba(255,255,255,1);
    }
    .img_wxcode {
      z-index: 101;
      position: absolute;
      top: 0;
      right: 0;
      width:50px;
      height:50px;
      background:rgba(255,255,255,1);
      border-radius:0px 8px 0px 16px;
      img {
        width: 35px;
        height: 35px;
        margin: 7.5px 7.5px;
      }
    }
  }
  .introduce_wrap {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background:rgba(0,0,0,0.8);
    .introduce_word {
      position: absolute;
      width:321px;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%,-50%);
      font-size:16px;
      font-family:Microsoft YaHei;
      font-weight:300;
      color:rgba(255,255,255,0.8);
      line-height:26px;
    }
  }
  .wxcode_wrap {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background:rgba(0,0,0,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    .wxcode_big_wrap {
      position: absolute;
      width:190px;
      height:200px;
      background:rgba(255,255,255,1);
      border-radius:8px;
    }
    .wxcode_big_img {
      width: 144px;
      height: 142px;
      margin: 12px 23px 0 23px;
    }
    .wxcode_tip {
      font-size:16px;
      font-family:Microsoft YaHei;
      font-weight:300;
      color:rgba(153,153,153,1);
      line-height:26px;
      text-align: center;
    }
  }

  .f-animLineUp {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.5s ease-in-out,transform 0.3s ease-in-out;
  }
  .wxcode_animScaleUp {
    transform: scale(0.8);
    transition: all 0.5s ease-in-out;
  }

}
.wxcode_cmp:hover .introduce_wrap {
  opacity: 1;
}
.wxcode_cmp:hover .f-animLineUp {
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s ease-in-out,transform 0.3s ease-in-out;
}
.wxcode_active:hover .introduce_wrap{
  opacity: 0 !important;
}
.wxcode_active:hover .wxcode_wrap {
  opacity: 1;
}
.wxcode_active:hover .wxcode_animScaleUp {
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.wxcode_cmp:hover .img_wxcode {
  opacity: 0.8;
}
