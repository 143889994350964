.micro_marketing_page {
  .ant-menu-horizontal > .ant-menu-item {
    height: 90px !important;
    padding-bottom: 30px;
    width: 295px !important;
    text-align: center;
    line-height: 90px !important;
    background:rgba(248,248,248,1) !important;
    box-sizing: border-box;
    font-size:20px!important;
    font-family:Microsoft YaHei!important;
    font-weight:400!important;
    // border-right: 1px solid #DEDEDE;
  }
}
.header_com {
  .ant-menu-horizontal {
    background: transparent;
  }

}

.weiCommercePage{
  .ant-carousel .slick-dots-bottom {
    bottom: 50px !important;

    
}
.ant-carousel .slick-dots li button{
width:10px !important;
height:10px !important;
background:rgba(255,255,255,1) !important;
opacity:0.5!important;
border-radius:50% !important;
}
.ant-carousel .slick-dots .slick-active button {
width:24px !important;
height:10px !important;
background:rgba(255,255,255,1) !important;
opacity:0.9 !important;
border-radius:5px !important;
}
}