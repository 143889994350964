.operation_slider_cmp {
  width:280px;
  height:586px;
  // background:rgba(255,190,64,1);
  border-radius:4px;
  position: relative;
  .slider_v_top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height:293px;
    background:rgba(243,249,255,1);
    border-radius:4px 4px 0px 0px;
    position: relative;
    .slider_icon {
      position: absolute;
      width: 280px;
    }
  }

  .slider_v_bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height:293px;
    background: #7DADFA;
    border-radius:0px 0px 4px 4px;
    font-family:Microsoft YaHei;
    font-weight:bold;
    color:rgba(255,255,255,1);
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    transition: all 0.3s ease-in-out;
    border-radius: 4px;
    .slider_hide_icon {
      position: absolute;
      bottom: 414px;
      left: 50%;
    }
    .slider_title {
      font-size:20px;
      position: absolute;
      height: 48px;
      line-height: 48px;
      bottom:305px;
      left: 50%;
      width: 100%;
      text-align: center;
    }
    .slider_intro {
      position: absolute;
      // bottom: 54px;
      bottom: 143px;
      width: 218px;
      font-size:16px;
      font-weight:300;
      color:rgba(255,255,255,1);
      line-height:30px;
      left: 50%;
    }
    .slider_mark {
      position: absolute;
      width:34px;
      height:5px;
      left: 123px;
      bottom: 50px;
      background:rgba(255,255,255,0.6);
      border-radius:2px;
    }
    .word_animLineUp1 {
      // transform: translateY(95px);
      transform: translate(-50%, 95px);
      transition: all 0.3s ease-in-out;
    }
    .word_animLineUp2 {
      // transform: translateY(95px);
       transform: translate(-50%, 95px);
      transition: all 0.3s ease-out;
    }
    .icon_animLineUp {
      opacity: 0;
      // transform: translateY(80px);
       transform: translate(-50%, 80px);
      transition: all 0.3s ease-in-out;
    }
  }
}
.operation_slider_cmp:hover .word_animLineUp1 {
  // transform: translateY(0);
  transform: translate(-50%, 0);
  transition: all 0.3s ease-in-out;
}
.operation_slider_cmp:hover .word_animLineUp2 {
  // transform: translateY(0);
  transform: translate(-50%, 0);
  transition: all 0.3s ease-out;
}
.operation_slider_cmp:hover .icon_animLineUp {
  opacity: 1;
  // transform: translateY(0);
  transform: translate(-50%, 0);
  transition: all 0.3s ease-in-out;
}
.operation_slider_cmp:hover .slider_v_bottom {
  height: 586px;
  background:linear-gradient(0deg,rgba(92,170,243,1) 0%,rgba(83,229,221,1) 100%);
  opacity:0.89;
  transition: all 0.3s ease-in-out;
}
.operation_slider_cmp:hover .slider_title {
  width:210px;
  height:48px;
  border:2px solid rgba(255,255,255,1);
  border-radius:24px;
  line-height: 48px;
  text-align: center;
}