.micro_marketing_page {
  background:rgba(248,248,248,1);
  border-radius: 8px;
  .banner_img {
    width: 100%;
    min-width: 1200px;
  }
  .menu_wrap {
    width: 1180px;
    height: 90px;
    display: flex;
    align-items: center;
    margin: 0 auto;
  }
  .section1,.section2,.section3,.section4 {
    position: relative;
  }
  .market_title {
    min-width: 1132px;
    text-align: center;
    font-size:34px;
    font-family:Lantinghei SC;
    font-weight:600;
    color:rgba(51,51,51,1);
    padding-top: 60px;
    // margin-top: 60px;
    // margin: 80px 0;
    // line-height:194px;
  }
  .micro_market_list {
    width: 1220px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 80px;
    .micro_market_item {
      background: red;
      width:245px;
      height:320px;
      background:rgba(255,255,255,1);
      border-radius:4px;
      margin-right: 80px;
      margin-top: 60px;
      position: relative;
    }
    .market_item_img {
      display: block;
      width: 104px;
      height: 104px;
      margin: 30px auto 55px;
    }
    .market_item_title {
      font-size:24px;
      font-family:Microsoft YaHei;
      font-weight:400;
      color:rgba(51,51,51,1);
      text-align: center;
      margin-bottom: 15px;
    }
    .market_item_content {
      width: 150px;
      font-size:16px;
      font-family:Microsoft YaHei;
      font-weight:400;
      color:rgba(153,153,153,1);
      line-height:27px;
      text-align: center;
      margin: 0 auto;
    }
    .no_margin_right {
      margin-right: 0;
    }
    .wx_code_wrap {
      position: absolute;
      top: 0;
      left: 0;
      width:245px;
      height:320px;
      border-radius:4px;
      opacity: 0;
      transition: 1s;
      transition-property: opacity;
      transition-timing-function: ease;
    }
    .light_box{
      z-index:101; 
      background:rgba(65,72,78,1);
      filter:alpha(Opacity=80);
      -moz-opacity:0.8;opacity: 0.8; 
      position:absolute; 
      top:0px;
      right: 0px;
      bottom: 0px; 
      left:0px;
      border-radius: 8px;
    }
    // .wx_code_wrap:hover {
    //   opacity: 1;
    // }
    .wx_code_img {
      width:200px;
      height:200px;
      border-radius:8px;
      position:absolute;
      z-index:105;
      left: 23px;
      top: 40px;
    }
    .wx_code_tip {
      z-index:105;
      position: absolute;
      bottom: 45px;
      font-size:16px;
      font-family:Microsoft YaHei;
      font-weight:400;
      color:rgba(255,255,255,1);
      line-height:1;
      opacity:0.7;
      text-align: center;
      left: 0;
      right: 0;
    }
  }
  
}