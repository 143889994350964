.microflow_page {
  .banner_img {
    // width: 19.2rem;
    // height: 5.01rem;
    width: 100%;
    min-width: 1200px;
  }
  .section1 {
    .market_title {
      min-width: 1132px;
      text-align: center;
      font-size:34px;
      font-family:Lantinghei SC;
      font-weight:600;
      color:rgba(51,51,51,1);
      margin: 80px 0;
      // line-height:194px;
    }
    .market_list {
      width: 1132px;
      margin: 0 auto;
      display: flex;
    }
    .market_list_border {
      width:1180px;
      height:1px;
      margin: 82px auto 0;
      border:1px solid rgba(221,221,221,1);
    }
    .market_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 283px;
      width: 195px;
      font-family:Microsoft YaHei;
      margin-right: 117px;
    }
    .market_item_title {
      font-size:24px;
      font-weight:400;
      color:rgba(102,102,102,1);
      line-height:49px;
    }
    .market_item_content {
      width: 195px;
      font-size:16px; 
      font-weight:300;
      color:rgba(153,153,153,1);
      line-height:26px;
      text-align: center;
    }
  }
  .section2 {
    .section2_bg1 {
      // width: 19.2rem;
      // height: 5.82rem;
      display: block;
      max-width: 100%;
      min-width: 1200px;
      margin: 0 auto;
      margin-top: 39px;
    }
  }
  .section3 {
    .section3_flowChat {
      // width: 19.2rem;
      // height: 2.06rem;
      display: block;
      max-width: 100%;
      min-width: 1200px;
      margin: 41px 0 0;
    }
  }
  .section4 {
    // width: 1298px;
    width: 1193px;
    margin: 65px auto 81px;
    display: flex;
    // justify-content: center;
    .section4_list {
      display: flex;
    }
    .section4_item {
      width: 198px;
      height: 120px;
      text-align: center;
    }
    .section4_item_title {
      font-size:20px;
      font-family:Microsoft YaHei;
      font-weight:400;
      color:rgba(51,51,51,1);
      margin-bottom: 24px;
    }
    .section4_item_content_list {
      font-size:16px;
      font-family:Microsoft YaHei;
      font-weight:400;
      color:rgba(153,153,153,1);
      line-height:26px;
    }
    .section_4_light_line {
      width:1px;
      height:119px;
      background: rgba(221,221,221,1);
      // border:1px solid rgba(221,221,221,1);
    }
    .section4_border {
      width:1px;
      height:119px;
      background:#999999;
    }
  }

}