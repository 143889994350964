.header_com {
  z-index: 99;
  .header_wrap_box {
    background: transparent;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 999;
  }
  .header_wrap {

    height: 72px;
    width: 1220px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    .header_logo {
      width: 113px;
      // height: 44px;
    }
    .menu_list {
      // width: 888px;
      flex: 1;
      padding-left: 85px;
      display: flex;
      font-size:16px;
      font-family:Microsoft YaHei;
      font-weight:400;
      // color:rgba(255,255,255,0.7);
    }

    .menu_item {
      line-height: 72px;
      padding: 0 8px;
      margin-right: 67px;
      cursor: pointer;
      color:rgba(255,255,255,0.7);
    }

    .menu_item:hover {
      color:  rgba(52,142,244,1) !important;
      border-bottom: 3px solid rgba(52,142,244,1);
    }
    .menu_item_active {
      color:  rgba(52,142,244,1) !important;
      border-bottom: 3px solid rgba(52,142,244,1);
    }
    .btn_wrap {
      width: 218px;
      .btn1 {
        display: inline-block;
        width: 80px;
        height: 32px;
        line-height: 32px;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 16px;
        text-align: center;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        margin-right: 40px;
        cursor: pointer;
      }
      .btn2 {
        display: inline-block;
        width: 98px;
        height: 32px;
        line-height: 32px;
        background: linear-gradient(
          -90deg,
          rgba(36, 212, 165, 1) 0%,
          rgba(14, 184, 174, 1) 100%
        );
        border-radius: 16px;
        text-align: center;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        cursor: pointer;
      }
    }
  }
  .header_wrap_active .menu_item {
    color:rgba(102,102,102,1);
  }
  .header_wrap_active .btn1 {
    color: rgba(102,102,102,1) !important;
    background:rgba(237,239,242,1) !important;
  }

  .header_content_wrap {
    position: fixed;
    top: 72px;
    left: 0px;
    right: 0px;
    height: 419px;
    z-index: 900;
    background: rgba(243, 244, 249, 1);
    .header_content {
      width: 888px;
      height: 419px;
      background: rgba(255, 255, 255, 1);
      margin: 0 auto;
      display: flex;
      transform: translateZ(100px);
      
    }
    .header_content_left {
      padding-top: 15px;
      border-right: 1px solid rgba(238, 238, 238, 1);
      width: 400px;
      height: 100%;
    }
    .header_content_right {
      padding-top: 15px;
      flex: 1;
    }
    .header_conter_item {
      width: 100%;
      height: 80px;
      padding: 0 0 0 40px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      cursor: pointer;
    
    }
    .header_conter_item:hover {
      background: rgba(247, 248, 250, 1);
    }
    .header_content_title {
      font-size: 20px;
      color: rgba(51, 51, 51, 1);
      line-height: 52px;
    }
    .header_content_subtitle {
      font-size: 16px;
      color: rgba(153, 153, 153, 1);
    }
  }
}
.unactive .f-animLineUp {
  transform: translateY(-419px);
  opacity: 0;
}
.active .f-animLineUp {
  transform: translateY(0);
  opacity: 1;
  transition: transform 0.3s, opacity 0.1s;
}
.unsubActive .sf-animLineUp {
  transform: translateY(40px);
  opacity: 0;
}
.subActive .sf-animLineUp {
  transform: translateY(0);
  opacity: 1;
  transition: all 0.3s;
}
