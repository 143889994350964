.slider_v_cmp {
  width:280px;
  height:586px;
  background:rgba(255,190,64,1);
  border-radius:4px;
  position: relative;
  .slider_v_top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height:293px;
    background:rgba(243,249,255,1);
    border-radius:4px 4px 0px 0px;
    position: relative;
    .slider_icon {
      position: absolute;
      width: 96px;
      top: 94px;
      left: 92px;
    }
  }

  .slider_v_bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height:293px;
    background:rgba(76,160,255,1);
    border-radius:0px 0px 4px 4px;
    font-family:Microsoft YaHei;
    font-weight:400;
    color:rgba(255,255,255,1);
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    transition: all 0.3s ease-in-out;
    border-radius: 4px;
    .slider_hide_icon {
      position: absolute;
      bottom: 314px;
      left: 50%;
      // transform: translateX(-50%);
    }
    .slider_title {
      font-size:24px;
      position: absolute;
      bottom: 250px;
      left: 50%;
      // transform: translateX(-50%);
    }
    .slider_intro {
      position: absolute;
      bottom: 202px;
      width: 229px;
      text-align: center;
      font-size:16px;
      line-height:26px;
      left: 50%;
      
    }
    .slider_mark {
      position: absolute;
      width:34px;
      height:5px;
      left: 123px;
      bottom: 50px;
      background:rgba(255,255,255,0.6);
      border-radius:2px;
    }
    .word_animLineUp1 {
      // transform: translateY(60px);
      transform: translate(-50%, 60px);
      transition: all 0.3s ease-in-out;
    }
    .word_animLineUp2 {
      // transform: translateY(60px);
      transform: translate(-50%, 60px);
      transition: all 0.3s ease-out;
    }
    .icon_animLineUp {
      opacity: 0;
      // transform: translateY(60px);
      transform: translate(-50%, 60px);
      transition: all 0.3s ease-in-out;
    }
  }
}
.slider_v_cmp:hover .word_animLineUp1 {
  // transform: translateY(0);
  // transform: translateX(-50%);
  transform: translate(-50%, 0);
  transition: all 0.3s ease-in-out;
}
.slider_v_cmp:hover .word_animLineUp2 {
  // transform: translateY(0);
  // transform: translateX(-50%);
  transform: translate(-50%, 0);
  transition: all 0.3s ease-out;
}
.slider_v_cmp:hover .icon_animLineUp {
  opacity: 1;
  // transform: translateY(0);
  // transform: translateX(-50%);
  transform: translate(-50%, 0);
  transition: all 0.3s ease-in-out;
}
.slider_v_cmp:hover .slider_v_bottom {
  height: 586px;
  transition: all 0.3s ease-in-out;
}