
.support-page {
    background-color: #f1f3f6;
}

.support-page {
    display: flex;
    justify-content: center;
    
    
}

.content-canves {
    width: 1180px;
    margin: 0 auto;
    position: relative;
}

.left-nav-panel {
    width: 200px;
    height: 400px;
    background-color: #fff;
    margin-right: 40px;
    margin-top: 100px;

    font-size:16px;
    font-family:Microsoft YaHei;
    font-weight:400;
    position: absolute;
    
}

.left-panel-btn {
    font-size:16px;
    font-family:Microsoft YaHei;
    font-weight:400;
}
.left-panel-btn1 {
    font-size:16px;
    font-family:Microsoft YaHei;
    font-weight:400;
    letter-spacing: 6px;
}

.tab-button-div-normal {
    height: 64px;
    display: flex;
    justify-content: center;

    font-size:16px;
    font-family:Microsoft YaHei;
    font-weight:400;
    color:rgba(102,102,102,1);
}

.tab-button-div-clicked {
    height: 64px;
    display: flex;
    justify-content: center;

    font-size:16px;
    font-family:Microsoft YaHei;
    font-weight:400;
    color:rgba(52,142,244,1);
    background-color:rgba(249,252,254,1);
}

.right-content-panel {
    width: 940px;
    background-color: #fff;
    margin-left: 240px;
    margin-top: 100px;
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 60px;
    padding-bottom: 70px;
    // position: fixed;
    
}



.back-to-top {
    width: 40px;
    height: 50px;
    position: fixed;
    right: 280px;
    bottom:25px;
    display: block;
    z-index:999;
    transform: translateZ(100px);

}

.segment-category-panel {
    background-color: #FBFBFB;
    margin-top:20px;
    padding:20px 30px;
    
    
}

.category-item {
    margin-bottom: 20px;
}

.category-item-link {
    font-size:18px;
    font-family:Microsoft YaHei;
    font-weight:400;
    color:rgba(52,142,244,1);
}

.setment-solution-panel {
    background-color: #FBFBFB;
    margin-top:43px;
    padding:20px 30px;
}
.solution-title {
    margin-bottom: 20px;
    font-size:18px;
    font-family:Microsoft YaHei;
    font-weight:400;
    color:rgba(102,102,102,1);

}
.solution-content {
    margin-bottom: 20px;
    font-size:14px;
    font-family:Microsoft YaHei;
    font-weight:400;
    color:rgba(153,153,153,1);
    line-height:25px;
}

.helper-image {
    margin: auto 0;
    max-width:775px;
    padding-bottom: 25px;
}

.rockie-helper-segment {
    display: none;
} 

.micro-mall-segment {
    display: none;
}
.micro-operation-segment {
    display: none;
}

.micro-marketing-segment {
    display: none;
} 

.micro-flow-segment {
    display: none;
}
.solution-item {
  position: relative;
}

