.slider_cmp {
  height: 298px;
  position: relative;
  .slider_font {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .slider_font_title {
      position: absolute;
      top: 96px;
      left: 70px;
      font-size:24px;
      font-family:Microsoft YaHei;
      font-weight:400;
      color:rgba(46,46,46,1);
    }
    .slider_font_intro {
      position: absolute;
      top: 160px;
      left: 70px;
      // width:235px;
      font-size:18px;
      font-family:Microsoft YaHei;
      font-weight:300;
      color:rgba(153,153,153,1);
      line-height:26px;
    }
  }
  .slider_back {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background:linear-gradient(90deg,rgba(35,173,245,1) 0%,rgba(30,222,229,1) 100%);
    // opacity:0.7;
    border-radius:8px;
    .slider_font_title {
      position: absolute;
      top: 67px;
      left: 70px;
      font-size:24px;
      font-family:Microsoft YaHei;
      font-weight:400;
      color:rgba(255,255,255,1);
    }
    .slider_font_intro {
      position: absolute;
      top: 110px;
      left: 70px;
      white-space:nowrap;
      // width:235px;
      font-size:18px;
      font-family:Microsoft YaHei;
      font-weight:300;
      color:rgba(255,255,255,1);
      line-height:26px;
    }
    .slider_font_more {
      position: absolute;
      top: 209px;
      left: 70px;
      font-size:18px;
      display: flex;
      align-items: center;
      font-family:Microsoft YaHei;
      font-weight:300;
      color:rgba(255,255,255,1);
      cursor:pointer;
    }
  }
  .sv-animLineR {
    width: 0;
    opacity: 0;
    transition: width 0.3s ease-in-out, opacity 0.3s ease-in;
  }
  .word_animLineR {
    transform: translateX(70px);
    transition: all 0.3s ease-in-out;
  }
  .word_fade {
    // opacity: 1;
    transition: all 1s ease-in;
  }
}
.slider_cmp:hover .sv-animLineR {
  width: 100%;
  opacity: 0.7;
  transition: width 0.3s ease-in-out, opacity 0.3s ease-in;
}
.slider_cmp:hover .word_animLineR {
  transform: translateX(0);
  transition: all 0.3s ease-in-out;
}
.slider_cmp:hover .word_fade {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}