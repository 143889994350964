@charset "UTF-8";
html {
  background-color: #f6f6f6;
  color: #000;
  font-size: 12px; }

body, ul, ol, dl, dd, h1, h2, h3, h4, h5, h6, figure, form, fieldset, legend, input, textarea, button, p, blockquote, th, td, pre, xmp {
  margin: 0;
  padding: 0; }

body, input, textarea, button, select, pre, xmp, tt, code, kbd, samp {
  line-height: 1.5;
  font-family: tahoma,arial,"Hiragino Sans GB",simsun,sans-serif; }

h1, h2, h3, h4, h5, h6, small, big, input, textarea, button, select {
  font-size: 100%; }

h1, h2, h3, h4, h5, h6 {
  font-family: tahoma,arial,"Hiragino Sans GB","微软雅黑",simsun,sans-serif; }

address, cite, dfn, em, i, optgroup, var {
  font-style: normal; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left; }

caption, th {
  text-align: inherit; }

ul, ol, menu {
  list-style: none; }

fieldset, img {
  border: 0; }

img, object, input, textarea, button, select {
  vertical-align: middle; }

article, aside, footer, header, section, nav, figure, figcaption, hgroup, details, menu {
  display: block; }

audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

blockquote:before, blockquote:after, q:before, q:after {
  content: "\0020"; }

textarea {
  overflow: auto;
  resize: vertical; }

input, textarea, button, select, a {
  outline: 0 none;
  border: none; }

button::-moz-focus-inner, input::-moz-focus-inner {
  padding: 0;
  border: 0; }

mark {
  background-color: transparent; }

a, ins, s, u, del {
  text-decoration: none; }

sup, sub {
  vertical-align: baseline; }

html {
  overflow-x: hidden;
  height: 100%;
  -webkit-tap-highlight-color: transparent; }

body {
  font-family: Arial, "Microsoft Yahei", "Helvetica Neue", Helvetica, sans-serif;
  color: #333;
  font-size: 16px;
  line-height: 1;
  -webkit-text-size-adjust: none; }

hr {
  height: .02rem;
  margin: .1rem 0;
  border: medium none;
  border-top: .02rem solid #cacaca; }

a {
  color: #25a4bb;
  text-decoration: none; }

.clearfix:after {
  content: ".";
  height: 0;
  display: block;
  clear: both;
  visibility: hidden; }

.clearfix {
  *zoom: 1; }

body {
  height: 100%; }

#root {
  box-sizing: border-box;
  background-color: #f6f6f6;
  height: 100%; }

.home_page {
  overflow: hidden; }
  .home_page .homepage {
    width: 100%;
    height: 100%;
    background-image: url(/static/media/homepage.fcb5a4e5.jpg);
    background-size: cover;
    background-position: center center;
    /* background-position: center; */
    background-repeat: no-repeat; }
  .home_page .footer_componey_info1 {
    position: absolute;
    bottom: 0;
    left: 620px;
    width: 1180px;
    height: 50px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 20px; }
  .home_page .footer_link1 {
    color: #333333; }
  .home_page .footer_link1:hover {
    color: #348ef4; }
  .home_page .ant-carousel .slick-slide {
    text-align: center;
    line-height: 160px;
    background: #364d79;
    overflow: hidden; }
  .home_page .ant-carousel .slick-slide h3 {
    color: #fff; }
  .home_page .indicator {
    position: absolute;
    right: 137px;
    bottom: 426px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .home_page .indicator .indicator_dot {
      display: flex;
      flex-direction: column; }
      .home_page .indicator .indicator_dot .dot {
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #fff9f5;
        opacity: 0.4;
        border-radius: 50%;
        margin-bottom: 14px; }
      .home_page .indicator .indicator_dot .active {
        background: #fff9f5;
        opacity: 0.8; }
    .home_page .indicator .indicator_dot_dark {
      display: flex;
      flex-direction: column; }
      .home_page .indicator .indicator_dot_dark .dot {
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #CCCCCC;
        border-radius: 50%;
        margin-bottom: 14px; }
      .home_page .indicator .indicator_dot_dark .active {
        background: #666666; }
    .home_page .indicator .swipe_down_icon {
      margin-top: 21px;
      width: 34px;
      height: 34px; }
  .home_page .wcard-container {
    width: 1050px;
    height: 822px;
    margin: 0 auto;
    margin-top: 76px;
    margin-top: 120px; }
    .home_page .wcard-container .typeface {
      width: 100%;
      height: 32px;
      font-size: 34px;
      font-family: Lantinghei SC;
      font-weight: 600;
      color: #333333;
      line-height: 86px;
      margin: 0 auto;
      text-align: center; }
    .home_page .wcard-container .wcard-above {
      display: flex;
      justify-content: center;
      height: 335px;
      top: 24px;
      position: relative; }
      .home_page .wcard-container .wcard-above .wcard-section {
        margin-top: 76px;
        position: absolute;
        left: 156px; }
      .home_page .wcard-container .wcard-above .above-topic {
        width: 280px;
        height: 51px;
        font-size: 20px;
        font-family: Lantinghei SC;
        font-weight: 600;
        color: #333333;
        line-height: 31px; }
      .home_page .wcard-container .wcard-above .above-topic1 {
        width: 162px;
        height: 30px;
        font-size: 20px;
        font-family: Lantinghei SC;
        font-weight: 600;
        color: #333333;
        line-height: 49px; }
      .home_page .wcard-container .wcard-above .above-introduce {
        width: 261px;
        height: 146px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 28px;
        margin-top: 28px; }
      .home_page .wcard-container .wcard-above .above-picture {
        position: absolute;
        left: 583px;
        top: 33px; }
    .home_page .wcard-container .wcard-below {
      display: flex;
      justify-content: center;
      height: 335px;
      top: 50px;
      position: relative; }
      .home_page .wcard-container .wcard-below .wcard-section-1 {
        margin-top: 165px;
        position: absolute;
        left: 708px; }
      .home_page .wcard-container .wcard-below .below-picture {
        position: absolute;
        left: 66px;
        top: -35px; }
      .home_page .wcard-container .wcard-below .below-topic {
        width: 180px;
        height: 20px;
        font-size: 20px;
        font-family: Lantinghei SC;
        font-weight: 600;
        color: #333333;
        line-height: 49px; }
      .home_page .wcard-container .wcard-below .below-introduce {
        width: 245px;
        height: 73px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 28px;
        margin-top: 30px; }
  .home_page .wcard-scene {
    width: 1180px;
    height: 666px;
    margin: 0 auto;
    margin-top: 140px; }
  .home_page .scene {
    width: 586px;
    height: 78px;
    font-size: 34px;
    font-family: Lantinghei SC;
    font-weight: 600;
    color: #333333;
    line-height: 49px;
    margin: 0 auto; }
  .home_page .operation {
    width: 137px;
    height: 64px;
    font-size: 34px;
    font-family: Lantinghei SC;
    font-weight: 600;
    color: #333333;
    line-height: 49px;
    margin: 0 auto; }
  .home_page .owe {
    width: 295px;
    height: 64px;
    font-size: 34px;
    font-family: Lantinghei SC;
    font-weight: 600;
    color: #333333;
    line-height: 49px;
    margin: 0 auto; }
  .home_page .marketing-list {
    display: flex;
    flex-wrap: wrap; }
  .home_page .more-case {
    width: 140px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    margin: 0 auto;
    background: linear-gradient(90deg, #4dc2ff 0%, #2a98ff 100%);
    border-radius: 24px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: white;
    margin-top: 51px;
    cursor: pointer; }

.microflow_page .banner_img {
  width: 100%;
  min-width: 1200px; }

.microflow_page .section1 .market_title {
  min-width: 1132px;
  text-align: center;
  font-size: 34px;
  font-family: Lantinghei SC;
  font-weight: 600;
  color: #333333;
  margin: 80px 0; }

.microflow_page .section1 .market_list {
  width: 1132px;
  margin: 0 auto;
  display: flex; }

.microflow_page .section1 .market_list_border {
  width: 1180px;
  height: 1px;
  margin: 82px auto 0;
  border: 1px solid #dddddd; }

.microflow_page .section1 .market_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 283px;
  width: 195px;
  font-family: Microsoft YaHei;
  margin-right: 117px; }

.microflow_page .section1 .market_item_title {
  font-size: 24px;
  font-weight: 400;
  color: #666666;
  line-height: 49px; }

.microflow_page .section1 .market_item_content {
  width: 195px;
  font-size: 16px;
  font-weight: 300;
  color: #999999;
  line-height: 26px;
  text-align: center; }

.microflow_page .section2 .section2_bg1 {
  display: block;
  max-width: 100%;
  min-width: 1200px;
  margin: 0 auto;
  margin-top: 39px; }

.microflow_page .section3 .section3_flowChat {
  display: block;
  max-width: 100%;
  min-width: 1200px;
  margin: 41px 0 0; }

.microflow_page .section4 {
  width: 1193px;
  margin: 65px auto 81px;
  display: flex; }
  .microflow_page .section4 .section4_list {
    display: flex; }
  .microflow_page .section4 .section4_item {
    width: 198px;
    height: 120px;
    text-align: center; }
  .microflow_page .section4 .section4_item_title {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin-bottom: 24px; }
  .microflow_page .section4 .section4_item_content_list {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 26px; }
  .microflow_page .section4 .section_4_light_line {
    width: 1px;
    height: 119px;
    background: #dddddd; }
  .microflow_page .section4 .section4_border {
    width: 1px;
    height: 119px;
    background: #999999; }

.micro_marketing_page {
  background: #f8f8f8;
  border-radius: 8px; }
  .micro_marketing_page .banner_img {
    width: 100%;
    min-width: 1200px; }
  .micro_marketing_page .menu_wrap {
    width: 1180px;
    height: 90px;
    display: flex;
    align-items: center;
    margin: 0 auto; }
  .micro_marketing_page .section1, .micro_marketing_page .section2, .micro_marketing_page .section3, .micro_marketing_page .section4 {
    position: relative; }
  .micro_marketing_page .market_title {
    min-width: 1132px;
    text-align: center;
    font-size: 34px;
    font-family: Lantinghei SC;
    font-weight: 600;
    color: #333333;
    padding-top: 60px; }
  .micro_marketing_page .micro_market_list {
    width: 1220px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 80px; }
    .micro_marketing_page .micro_market_list .micro_market_item {
      background: red;
      width: 245px;
      height: 320px;
      background: white;
      border-radius: 4px;
      margin-right: 80px;
      margin-top: 60px;
      position: relative; }
    .micro_marketing_page .micro_market_list .market_item_img {
      display: block;
      width: 104px;
      height: 104px;
      margin: 30px auto 55px; }
    .micro_marketing_page .micro_market_list .market_item_title {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      text-align: center;
      margin-bottom: 15px; }
    .micro_marketing_page .micro_market_list .market_item_content {
      width: 150px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 27px;
      text-align: center;
      margin: 0 auto; }
    .micro_marketing_page .micro_market_list .no_margin_right {
      margin-right: 0; }
    .micro_marketing_page .micro_market_list .wx_code_wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 245px;
      height: 320px;
      border-radius: 4px;
      opacity: 0;
      transition: 1s;
      transition-property: opacity;
      transition-timing-function: ease; }
    .micro_marketing_page .micro_market_list .light_box {
      z-index: 101;
      background: #41484e;
      filter: alpha(Opacity=80);
      -moz-opacity: 0.8;
      opacity: 0.8;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      border-radius: 8px; }
    .micro_marketing_page .micro_market_list .wx_code_img {
      width: 200px;
      height: 200px;
      border-radius: 8px;
      position: absolute;
      z-index: 105;
      left: 23px;
      top: 40px; }
    .micro_marketing_page .micro_market_list .wx_code_tip {
      z-index: 105;
      position: absolute;
      bottom: 45px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: white;
      line-height: 1;
      opacity: 0.7;
      text-align: center;
      left: 0;
      right: 0; }

.micro_marketing_page .ant-menu-horizontal > .ant-menu-item {
  height: 90px !important;
  padding-bottom: 30px;
  width: 295px !important;
  text-align: center;
  line-height: 90px !important;
  background: #f8f8f8 !important;
  box-sizing: border-box;
  font-size: 20px !important;
  font-family: Microsoft YaHei !important;
  font-weight: 400 !important; }

.header_com .ant-menu-horizontal {
  background: transparent; }

.weiCommercePage .ant-carousel .slick-dots-bottom {
  bottom: 50px !important; }

.weiCommercePage .ant-carousel .slick-dots li button {
  width: 10px !important;
  height: 10px !important;
  background: white !important;
  opacity: 0.5 !important;
  border-radius: 50% !important; }

.weiCommercePage .ant-carousel .slick-dots .slick-active button {
  width: 24px !important;
  height: 10px !important;
  background: white !important;
  opacity: 0.9 !important;
  border-radius: 5px !important; }

.banner_img {
  width: 100%;
  min-width: 1200px; }

.odd-card {
  background-color: #FAFCFF; }

.even-card {
  background-color: #fff; }

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.card-canves {
  width: 1161px;
  height: 588px;
  margin: 0 auto;
  display: flex;
  justify-content: center; }

.card-left {
  width: 554px;
  height: 434px;
  margin-bottom: 76px;
  margin-top: 50px;
  margin-right: 200px; }

.card-right {
  width: 554px;
  height: 434px;
  margin-bottom: 76px;
  margin-top: 50px; }

.card-secTitle {
  font-size: 20px;
  color: #333;
  font-weight: 250;
  font-family: MicrosoftYaHeiLight;
  margin-top: 19px; }

.card-secSummary {
  font-size: 16px;
  color: #999;
  font-weight: 200;
  font-family: MicrosoftYaHeiLight;
  line-height: 25px;
  margin-top: 8px; }

.card-topic {
  font-size: 34px;
  font-family: Lantinghei SC;
  font-weight: 500;
  color: #333333;
  line-height: 49px;
  padding-top: 80px;
  text-align: center; }

.card-title {
  font-size: 28px;
  font-family: Lantinghei SC;
  font-weight: 450;
  color: #333333;
  line-height: 49px;
  margin-top: 28px; }

.ant-btn-primary {
  width: 140px;
  height: 40px;
  border-radius: 20px;
  font-size: 16px;
  font-family: Lantinghei SC;
  font-weight: 200;
  border: 0 none currentColor;
  margin-bottom: 15px; }

.card-button {
  margin-top: 60px; }
  .card-button .ant-btn-primary {
    background: linear-gradient(90deg, #4dc2ff 0%, #2a98ff 100%); }

.detail-link {
  text-decoration: underline;
  margin-left: 60px;
  font-size: 16px;
  font-family: Lantinghei SC;
  font-weight: 200;
  color: #348ef4; }

.poker-table {
  display: flex;
  justify-content: center;
  margin: 60px auto 80px; }

.poker-1 {
  width: 280px;
  height: 511px;
  display: flex;
  flex-direction: column;
  margin: 0px 13px 0px;
  border-radius: 8px;
  transition: background-color 0.3s ease-in-out , box-shadow 0.3s ease-in-out; }
  .poker-1 .ant-btn-primary {
    transition: background-color 0.3s ease-in-out; }
  .poker-1 .ant-btn-link {
    transition: background-color 0.1s ease-in-out; }

.poker-1-1 {
  background-color: #FFFBF9; }
  .poker-1-1 .ant-btn-primary {
    background-color: #FFECE3;
    color: #FF7121; }

.poker-1-2 {
  background-color: #F5FBFF; }
  .poker-1-2 .ant-btn-primary {
    background-color: #DEF2FF;
    color: #348EF4; }

.poker-1-3 {
  background-color: #F3FFFC; }
  .poker-1-3 .ant-btn-primary {
    background-color: #DCFCF4;
    color: #2AB794; }

.poker-1-4 {
  background-color: #F9F8FF; }
  .poker-1-4 .ant-btn-primary {
    background-color: #ECE9FF;
    color: #8375DC; }

.poker-1:hover {
  background-color: #fffbf9;
  box-shadow: 0px 7px 32px 0px rgba(184, 214, 230, 0.48); }
  .poker-1:hover .ant-btn-primary {
    background: linear-gradient(90deg, #4dc2ff 0%, #2a98ff 100%);
    color: #fff; }

.poker-head {
  height: 300px;
  width: 280;
  padding-top: 187px;
  background-size: cover; }

.poker-mall {
  background: url(/static/media/icon_mall.ba767a90.png); }

.poker-shop {
  background: url(/static/media/icon_shop.74c0b880.png); }

.poker-server {
  background: url(/static/media/icon_server.0b5527f2.png); }

.poker-brand {
  background: url(/static/media/icon_brand.1712fcb6.png); }

.poker-head-title {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: white;
  line-height: 49px;
  text-align: center; }

.poker-head-summary {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: white;
  line-height: 49px;
  opacity: 0.8;
  text-align: center; }

.poker-content {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 25px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #999999;
  line-height: 25px; }

.btn-box {
  display: flex;
  justify-content: center;
  align-items: center; }
  .btn-box .ant-btn-primary {
    margin-top: 30px; }

.poker-2 {
  background-color: #fff;
  width: 280px;
  height: 300px;
  border-radius: 8px;
  display: inline-block;
  padding-top: 27px;
  margin: 0px 13px 0px;
  text-align: center;
  transition: box-shadow 0.3s ease-in-out; }

.poker-2:hover {
  box-shadow: 0px 7px 32px 0px rgba(184, 214, 230, 0.48); }

.poker2-content {
  text-align: center;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 49px; }

.poker2-icon {
  width: 170px;
  height: 170px;
  margin-bottom: 18px; }

.card-table {
  background-color: #999;
  text-align: center; }

.img-box {
  text-align: center; }

.partner-brand {
  width: 941px;
  height: 248px;
  margin-top: 60px;
  margin-bottom: 80px; }

.support-page {
  background-color: #f1f3f6; }

.support-page {
  display: flex;
  justify-content: center; }

.content-canves {
  width: 1180px;
  margin: 0 auto;
  position: relative; }

.left-nav-panel {
  width: 200px;
  height: 400px;
  background-color: #fff;
  margin-right: 40px;
  margin-top: 100px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  position: absolute; }

.left-panel-btn {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400; }

.left-panel-btn1 {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  letter-spacing: 6px; }

.tab-button-div-normal {
  height: 64px;
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666; }

.tab-button-div-clicked {
  height: 64px;
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #348ef4;
  background-color: #f9fcfe; }

.right-content-panel {
  width: 940px;
  background-color: #fff;
  margin-left: 240px;
  margin-top: 100px;
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 60px;
  padding-bottom: 70px; }

.back-to-top {
  width: 40px;
  height: 50px;
  position: fixed;
  right: 280px;
  bottom: 25px;
  display: block;
  z-index: 999;
  transform: translateZ(100px); }

.segment-category-panel {
  background-color: #FBFBFB;
  margin-top: 20px;
  padding: 20px 30px; }

.category-item {
  margin-bottom: 20px; }

.category-item-link {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #348ef4; }

.setment-solution-panel {
  background-color: #FBFBFB;
  margin-top: 43px;
  padding: 20px 30px; }

.solution-title {
  margin-bottom: 20px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666; }

.solution-content {
  margin-bottom: 20px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 25px; }

.helper-image {
  margin: auto 0;
  max-width: 775px;
  padding-bottom: 25px; }

.rockie-helper-segment {
  display: none; }

.micro-mall-segment {
  display: none; }

.micro-operation-segment {
  display: none; }

.micro-marketing-segment {
  display: none; }

.micro-flow-segment {
  display: none; }

.solution-item {
  position: relative; }

.header_com {
  z-index: 99; }
  .header_com .header_wrap_box {
    background: transparent;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 999; }
  .header_com .header_wrap {
    height: 72px;
    width: 1220px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center; }
    .header_com .header_wrap .header_logo {
      width: 113px; }
    .header_com .header_wrap .menu_list {
      flex: 1 1;
      padding-left: 85px;
      display: flex;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400; }
    .header_com .header_wrap .menu_item {
      line-height: 72px;
      padding: 0 8px;
      margin-right: 67px;
      cursor: pointer;
      color: rgba(255, 255, 255, 0.7); }
    .header_com .header_wrap .menu_item:hover {
      color: #348ef4 !important;
      border-bottom: 3px solid #348ef4; }
    .header_com .header_wrap .menu_item_active {
      color: #348ef4 !important;
      border-bottom: 3px solid #348ef4; }
    .header_com .header_wrap .btn_wrap {
      width: 218px; }
      .header_com .header_wrap .btn_wrap .btn1 {
        display: inline-block;
        width: 80px;
        height: 32px;
        line-height: 32px;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 16px;
        text-align: center;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: white;
        margin-right: 40px;
        cursor: pointer; }
      .header_com .header_wrap .btn_wrap .btn2 {
        display: inline-block;
        width: 98px;
        height: 32px;
        line-height: 32px;
        background: linear-gradient(-90deg, #24d4a5 0%, #0eb8ae 100%);
        border-radius: 16px;
        text-align: center;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: white;
        cursor: pointer; }
  .header_com .header_wrap_active .menu_item {
    color: #666666; }
  .header_com .header_wrap_active .btn1 {
    color: #666666 !important;
    background: #edeff2 !important; }
  .header_com .header_content_wrap {
    position: fixed;
    top: 72px;
    left: 0px;
    right: 0px;
    height: 419px;
    z-index: 900;
    background: #f3f4f9; }
    .header_com .header_content_wrap .header_content {
      width: 888px;
      height: 419px;
      background: white;
      margin: 0 auto;
      display: flex;
      transform: translateZ(100px); }
    .header_com .header_content_wrap .header_content_left {
      padding-top: 15px;
      border-right: 1px solid #eeeeee;
      width: 400px;
      height: 100%; }
    .header_com .header_content_wrap .header_content_right {
      padding-top: 15px;
      flex: 1 1; }
    .header_com .header_content_wrap .header_conter_item {
      width: 100%;
      height: 80px;
      padding: 0 0 0 40px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      cursor: pointer; }
    .header_com .header_content_wrap .header_conter_item:hover {
      background: #f7f8fa; }
    .header_com .header_content_wrap .header_content_title {
      font-size: 20px;
      color: #333333;
      line-height: 52px; }
    .header_com .header_content_wrap .header_content_subtitle {
      font-size: 16px;
      color: #999999; }

.unactive .f-animLineUp {
  transform: translateY(-419px);
  opacity: 0; }

.active .f-animLineUp {
  transform: translateY(0);
  opacity: 1;
  transition: transform 0.3s, opacity 0.1s; }

.unsubActive .sf-animLineUp {
  transform: translateY(40px);
  opacity: 0; }

.subActive .sf-animLineUp {
  transform: translateY(0);
  opacity: 1;
  transition: all 0.3s; }

.footer_com .section1 {
  position: relative; }
  .footer_com .section1 .section1_img {
    width: 100%;
    min-width: 1366px; }
  .footer_com .section1 .img_content {
    z-index: 99;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .footer_com .section1 .img_content_word {
    font-size: 48px;
    font-family: Lantinghei SC;
    font-weight: 600;
    color: white;
    opacity: 0.85;
    margin-bottom: 46px;
    text-align: center; }
  .footer_com .section1 .img_content_tip {
    width: 728px;
    height: 84px;
    background-image: url(/static/media/intro_process.a0adb166.png);
    border: 1px solid #156572;
    border-radius: 4px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 54px; }
  .footer_com .section1 .img_content_btn {
    display: inline-block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 164px;
    height: 44px;
    background: linear-gradient(90deg, #24d4a5 0%, #0eb8ae 100%);
    opacity: 0.9;
    border-radius: 24px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #fefefe;
    text-align: center;
    line-height: 44px; }

.footer_com .section2 {
  line-height: 1;
  margin-top: -1px;
  width: 100%;
  height: 420px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB4AAAAGkAQMAAADqgaqfAAAABlBMVEVHcEwPGCVdaY9yAAAAAXRSTlMAQObYZgAAAaRJREFUeNrtzzEBAAAIA6A1s38qjeExaEDSZraLsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLDwjwPrgSPLy8mVKwAAAABJRU5ErkJggg==);
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 62px; }
  .footer_com .section2 .footer_content {
    width: 1180px;
    height: 222px;
    display: flex;
    margin: 0 auto;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.4); }
  .footer_com .section2 .footer_menu_title {
    padding: 0 0 22px; }
  .footer_com .section2 .footer_menu_item {
    display: block;
    font-size: 14px;
    margin-bottom: 20px;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer; }
  .footer_com .section2 .footer_menu_item2 {
    font-size: 14px;
    margin-bottom: 20px;
    color: rgba(255, 255, 255, 0.7); }
  .footer_com .section2 .scane_code_panne {
    width: 147px; }
    .footer_com .section2 .scane_code_panne .img_wrap {
      width: 132px;
      height: 132px;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIQAAACEBAMAAACjap6UAAAAD1BMVEX///////////////////+Rd1MVAAAABXRSTlMCEgtORr+rowoAAAHwSURBVGje7VrbbcMwDCS4gYwOIGqFTFAg+89UkUfJVJKmRW2gacGTQ4uvo7/iExK6XC7v9GO89XY6g4IO4h9RXI9SXKkcpThMkEgkEolEIpH4C6i6OHgc3AjuC6WVR5cWVtvbxjDa+R6o9w+6GMw6VAtsit6di8LlM3vYSqtt4BLxTDBa59C+b8OO56gRCFBDRSgMNe1xYJ1BjRvXBqtLt/OymN7VtVB3UWUb1htZJnBUlLLT7BzwtQBVFeOqUhjpAp6W1yjvgT1D7TBOothuYrLbmNoklkig2DYBtmI2oEhb9jdpB3mndF7Ly0Dvbprr3Rr3vdMFNPKqzlS6LWr7TdSRYuVqNOhPOSyauqVi+RHCiIJljILuDZ7bzcZhsFB5Bi3RbgAt1hvxnOJbSIqkSIqk+D6FyCGC/g2uX9RHIEKi9gDsbbb99jtVXuXNDvkEvdX8Y6oKTtRY+1Y10tRd5FJoaCh2oQRBpHWQR0MveYWxu+qiqRNJ95CBN+qQadeatMhKhk5eIjw5Vhr+RHmaxIWu3mUuuwTn6aOCpz4nXDNHUOaQy3VWgoGZ9kWuz+fD05gzDwDVpzKEPjtT4Bjc0OQ8mP1BHp4c7g8d633NMD1K16/PMpzHuUQikUgkXgkn/Dx9wo/k+Z+DQPEK/wL5ANpLA8vctt+qAAAAAElFTkSuQmCC);
      margin: 0 auto 26px; }
    .footer_com .section2 .scane_code_panne .Code_wrap {
      width: 104px;
      height: 104px;
      margin: 14px; }
    .footer_com .section2 .scane_code_panne .img_tip {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #adb0b3; }
  .footer_com .section2 .footer_componey_info {
    width: 1180px;
    height: 135px;
    margin: 0 auto;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.2);
    line-height: 94px;
    text-align: center; }
  .footer_com .section2 .footer_link {
    color: rgba(255, 255, 255, 0.2); }
  .footer_com .section2 .footer_link:hover {
    color: #348ef4; }

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.micro_operation_page .banner_img {
  width: 100%;
  min-width: 1200px; }

.micro_operation_page .section_1 {
  background: white;
  overflow: hidden; }
  .micro_operation_page .section_1 .section_bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, #5caaf3 0%, #53e5dd 100%);
    opacity: 0;
    border-radius: 8px; }
  .micro_operation_page .section_1 .subtitle_2 {
    position: absolute;
    width: 100%;
    font-size: 18px;
    top: 324px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    text-align: center; }
  .micro_operation_page .section_1 .section_1_list {
    position: relative;
    width: 1180px;
    height: 821px;
    margin: 0  auto 80px;
    font-size: 28px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: white; }
    .micro_operation_page .section_1 .section_1_list .section_bg_1 {
      width: 580px;
      height: 341px;
      position: absolute;
      left: 0;
      top: 0;
      background-image: url(/static/media/bg1.b7045f52.png); }
      .micro_operation_page .section_1 .section_1_list .section_bg_1 .icon_1 {
        position: absolute;
        top: 84px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 99; }
      .micro_operation_page .section_1 .section_1_list .section_bg_1 .title_1 {
        position: absolute;
        top: 230px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 99; }
      .micro_operation_page .section_1 .section_1_list .section_bg_1 .subtitle_2 {
        top: 278px; }
    .micro_operation_page .section_1 .section_1_list .section_bg_1:hover .section_bg {
      opacity: 0.6; }
    .micro_operation_page .section_1 .section_1_list .section_bg_2 {
      width: 580px;
      height: 461px;
      position: absolute;
      right: 0;
      top: 0;
      background-image: url(/static/media/bg2.4889b95b.png); }
      .micro_operation_page .section_1 .section_1_list .section_bg_2 .icon_2 {
        position: absolute;
        top: 120px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 99; }
      .micro_operation_page .section_1 .section_1_list .section_bg_2 .title_2 {
        position: absolute;
        top: 276px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 99; }
    .micro_operation_page .section_1 .section_1_list .section_bg_2:hover .section_bg {
      opacity: 0.6; }
    .micro_operation_page .section_1 .section_1_list .section_bg_3 {
      width: 580px;
      height: 461px;
      position: absolute;
      left: 0;
      bottom: 0;
      background-image: url(/static/media/bg3.68b55bb8.png); }
      .micro_operation_page .section_1 .section_1_list .section_bg_3 .icon_3 {
        position: absolute;
        top: 143px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 99; }
      .micro_operation_page .section_1 .section_1_list .section_bg_3 .title_3 {
        position: absolute;
        top: 291px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 99; }
      .micro_operation_page .section_1 .section_1_list .section_bg_3 .subtitle_2 {
        top: 339px; }
    .micro_operation_page .section_1 .section_1_list .section_bg_3:hover .section_bg {
      opacity: 0.6; }
    .micro_operation_page .section_1 .section_1_list .section_bg_4 {
      width: 580px;
      height: 341px;
      position: absolute;
      right: 0;
      bottom: 0;
      background-image: url(/static/media/bg4.ba8cbdf3.png); }
      .micro_operation_page .section_1 .section_1_list .section_bg_4 .icon_4 {
        position: absolute;
        top: 85px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 99; }
      .micro_operation_page .section_1 .section_1_list .section_bg_4 .title_4 {
        position: absolute;
        top: 230px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 99; }
      .micro_operation_page .section_1 .section_1_list .section_bg_4 .subtitle_2 {
        top: 278px; }
    .micro_operation_page .section_1 .section_1_list .section_bg_4:hover .section_bg {
      opacity: 0.6; }

.micro_operation_page .section_1_title {
  min-width: 1132px;
  text-align: center;
  margin: 80px 0 60px;
  font-size: 34px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333; }

.micro_operation_page .section_3 {
  overflow: hidden;
  background: #FAFCFF; }
  .micro_operation_page .section_3 .img_wrap {
    width: 1181px;
    margin: 0 auto 90px; }

.micro_operation_page .section_4 {
  background: #FFFFFF;
  overflow: hidden; }
  .micro_operation_page .section_4 .section_4_list {
    width: 1180px;
    margin: 0 auto;
    display: flex;
    margin-bottom: 80px; }

.micro_operation_page .section_5 {
  overflow: hidden;
  background: #FAFCFF; }
  .micro_operation_page .section_5 .section_5_list {
    width: 1180px;
    margin: 0 auto;
    display: flex;
    margin-bottom: 48px;
    flex-wrap: wrap; }

.micro_operation_page .more-case {
  width: 140px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  margin: 0 auto;
  background: linear-gradient(90deg, #4dc2ff 0%, #2a98ff 100%);
  border-radius: 24px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: white;
  margin-bottom: 41px;
  cursor: pointer; }

.operation_slider_cmp {
  width: 280px;
  height: 586px;
  border-radius: 4px;
  position: relative; }
  .operation_slider_cmp .slider_v_top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 293px;
    background: #f3f9ff;
    border-radius: 4px 4px 0px 0px;
    position: relative; }
    .operation_slider_cmp .slider_v_top .slider_icon {
      position: absolute;
      width: 280px; }
  .operation_slider_cmp .slider_v_bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 293px;
    background: #7DADFA;
    border-radius: 0px 0px 4px 4px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: white;
    transition: all 0.3s ease-in-out;
    border-radius: 4px; }
    .operation_slider_cmp .slider_v_bottom .slider_hide_icon {
      position: absolute;
      bottom: 414px;
      left: 50%; }
    .operation_slider_cmp .slider_v_bottom .slider_title {
      font-size: 20px;
      position: absolute;
      height: 48px;
      line-height: 48px;
      bottom: 305px;
      left: 50%;
      width: 100%;
      text-align: center; }
    .operation_slider_cmp .slider_v_bottom .slider_intro {
      position: absolute;
      bottom: 143px;
      width: 218px;
      font-size: 16px;
      font-weight: 300;
      color: white;
      line-height: 30px;
      left: 50%; }
    .operation_slider_cmp .slider_v_bottom .slider_mark {
      position: absolute;
      width: 34px;
      height: 5px;
      left: 123px;
      bottom: 50px;
      background: rgba(255, 255, 255, 0.6);
      border-radius: 2px; }
    .operation_slider_cmp .slider_v_bottom .word_animLineUp1 {
      transform: translate(-50%, 95px);
      transition: all 0.3s ease-in-out; }
    .operation_slider_cmp .slider_v_bottom .word_animLineUp2 {
      transform: translate(-50%, 95px);
      transition: all 0.3s ease-out; }
    .operation_slider_cmp .slider_v_bottom .icon_animLineUp {
      opacity: 0;
      transform: translate(-50%, 80px);
      transition: all 0.3s ease-in-out; }

.operation_slider_cmp:hover .word_animLineUp1 {
  transform: translate(-50%, 0);
  transition: all 0.3s ease-in-out; }

.operation_slider_cmp:hover .word_animLineUp2 {
  transform: translate(-50%, 0);
  transition: all 0.3s ease-out; }

.operation_slider_cmp:hover .icon_animLineUp {
  opacity: 1;
  transform: translate(-50%, 0);
  transition: all 0.3s ease-in-out; }

.operation_slider_cmp:hover .slider_v_bottom {
  height: 586px;
  background: linear-gradient(0deg, #5caaf3 0%, #53e5dd 100%);
  opacity: 0.89;
  transition: all 0.3s ease-in-out; }

.operation_slider_cmp:hover .slider_title {
  width: 210px;
  height: 48px;
  border: 2px solid white;
  border-radius: 24px;
  line-height: 48px;
  text-align: center; }

.slider_cmp {
  height: 298px;
  position: relative; }
  .slider_cmp .slider_font {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
    .slider_cmp .slider_font .slider_font_title {
      position: absolute;
      top: 96px;
      left: 70px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #2e2e2e; }
    .slider_cmp .slider_font .slider_font_intro {
      position: absolute;
      top: 160px;
      left: 70px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      color: #999999;
      line-height: 26px; }
  .slider_cmp .slider_back {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(90deg, #23adf5 0%, #1edee5 100%);
    border-radius: 8px; }
    .slider_cmp .slider_back .slider_font_title {
      position: absolute;
      top: 67px;
      left: 70px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: white; }
    .slider_cmp .slider_back .slider_font_intro {
      position: absolute;
      top: 110px;
      left: 70px;
      white-space: nowrap;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      color: white;
      line-height: 26px; }
    .slider_cmp .slider_back .slider_font_more {
      position: absolute;
      top: 209px;
      left: 70px;
      font-size: 18px;
      display: flex;
      align-items: center;
      font-family: Microsoft YaHei;
      font-weight: 300;
      color: white;
      cursor: pointer; }
  .slider_cmp .sv-animLineR {
    width: 0;
    opacity: 0;
    transition: width 0.3s ease-in-out, opacity 0.3s ease-in; }
  .slider_cmp .word_animLineR {
    transform: translateX(70px);
    transition: all 0.3s ease-in-out; }
  .slider_cmp .word_fade {
    transition: all 1s ease-in; }

.slider_cmp:hover .sv-animLineR {
  width: 100%;
  opacity: 0.7;
  transition: width 0.3s ease-in-out, opacity 0.3s ease-in; }

.slider_cmp:hover .word_animLineR {
  transform: translateX(0);
  transition: all 0.3s ease-in-out; }

.slider_cmp:hover .word_fade {
  opacity: 0;
  transition: all 0.3s ease-in-out; }

.slider_v_cmp {
  width: 280px;
  height: 586px;
  background: #ffbe40;
  border-radius: 4px;
  position: relative; }
  .slider_v_cmp .slider_v_top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 293px;
    background: #f3f9ff;
    border-radius: 4px 4px 0px 0px;
    position: relative; }
    .slider_v_cmp .slider_v_top .slider_icon {
      position: absolute;
      width: 96px;
      top: 94px;
      left: 92px; }
  .slider_v_cmp .slider_v_bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 293px;
    background: #4ca0ff;
    border-radius: 0px 0px 4px 4px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: white;
    transition: all 0.3s ease-in-out;
    border-radius: 4px; }
    .slider_v_cmp .slider_v_bottom .slider_hide_icon {
      position: absolute;
      bottom: 314px;
      left: 50%; }
    .slider_v_cmp .slider_v_bottom .slider_title {
      font-size: 24px;
      position: absolute;
      bottom: 250px;
      left: 50%; }
    .slider_v_cmp .slider_v_bottom .slider_intro {
      position: absolute;
      bottom: 202px;
      width: 229px;
      text-align: center;
      font-size: 16px;
      line-height: 26px;
      left: 50%; }
    .slider_v_cmp .slider_v_bottom .slider_mark {
      position: absolute;
      width: 34px;
      height: 5px;
      left: 123px;
      bottom: 50px;
      background: rgba(255, 255, 255, 0.6);
      border-radius: 2px; }
    .slider_v_cmp .slider_v_bottom .word_animLineUp1 {
      transform: translate(-50%, 60px);
      transition: all 0.3s ease-in-out; }
    .slider_v_cmp .slider_v_bottom .word_animLineUp2 {
      transform: translate(-50%, 60px);
      transition: all 0.3s ease-out; }
    .slider_v_cmp .slider_v_bottom .icon_animLineUp {
      opacity: 0;
      transform: translate(-50%, 60px);
      transition: all 0.3s ease-in-out; }

.slider_v_cmp:hover .word_animLineUp1 {
  transform: translate(-50%, 0);
  transition: all 0.3s ease-in-out; }

.slider_v_cmp:hover .word_animLineUp2 {
  transform: translate(-50%, 0);
  transition: all 0.3s ease-out; }

.slider_v_cmp:hover .icon_animLineUp {
  opacity: 1;
  transform: translate(-50%, 0);
  transition: all 0.3s ease-in-out; }

.slider_v_cmp:hover .slider_v_bottom {
  height: 586px;
  transition: all 0.3s ease-in-out; }

.wxcode_cmp {
  width: 380px;
  height: 273px;
  position: relative; }
  .wxcode_cmp .img_wrap {
    position: relative; }
    .wxcode_cmp .img_wrap .img_componey {
      width: 380px; }
    .wxcode_cmp .img_wrap .img_title {
      z-index: 99;
      position: absolute;
      top: 0;
      left: 0;
      height: 48px;
      line-height: 48px;
      padding: 0 20px;
      background: black;
      opacity: 0.7;
      border-radius: 8px 0px 20px 0px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: white; }
    .wxcode_cmp .img_wrap .img_wxcode {
      z-index: 101;
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      height: 50px;
      background: white;
      border-radius: 0px 8px 0px 16px; }
      .wxcode_cmp .img_wrap .img_wxcode img {
        width: 35px;
        height: 35px;
        margin: 7.5px 7.5px; }
  .wxcode_cmp .introduce_wrap {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8); }
    .wxcode_cmp .introduce_wrap .introduce_word {
      position: absolute;
      width: 321px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      color: rgba(255, 255, 255, 0.8);
      line-height: 26px; }
  .wxcode_cmp .wxcode_wrap {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center; }
    .wxcode_cmp .wxcode_wrap .wxcode_big_wrap {
      position: absolute;
      width: 190px;
      height: 200px;
      background: white;
      border-radius: 8px; }
    .wxcode_cmp .wxcode_wrap .wxcode_big_img {
      width: 144px;
      height: 142px;
      margin: 12px 23px 0 23px; }
    .wxcode_cmp .wxcode_wrap .wxcode_tip {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      color: #999999;
      line-height: 26px;
      text-align: center; }
  .wxcode_cmp .f-animLineUp {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.5s ease-in-out,transform 0.3s ease-in-out; }
  .wxcode_cmp .wxcode_animScaleUp {
    transform: scale(0.8);
    transition: all 0.5s ease-in-out; }

.wxcode_cmp:hover .introduce_wrap {
  opacity: 1; }

.wxcode_cmp:hover .f-animLineUp {
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s ease-in-out,transform 0.3s ease-in-out; }

.wxcode_active:hover .introduce_wrap {
  opacity: 0 !important; }

.wxcode_active:hover .wxcode_wrap {
  opacity: 1; }

.wxcode_active:hover .wxcode_animScaleUp {
  transform: scale(1);
  transition: all 0.5s ease-in-out; }

.wxcode_cmp:hover .img_wxcode {
  opacity: 0.8; }

.tab_cmp_page {
  height: 100%;
  background-size: cover;
  background-position: center center;
  /* background-position: center; */
  background-repeat: no-repeat; }
  .tab_cmp_page .tab_wrap {
    position: relative;
    width: 1180px;
    height: 100%;
    margin: 0 auto; }
    .tab_cmp_page .tab_wrap .tab_title {
      font-size: 34px;
      font-family: Lantinghei SC;
      font-weight: 600;
      color: white;
      line-height: 49px;
      text-align: center;
      padding-top: 140px; }
    .tab_cmp_page .tab_wrap .tab_content {
      width: 1180px;
      position: absolute;
      top: 184px;
      left: 0;
      right: 0;
      bottom: 0;
      margin-top: 40px; }
    .tab_cmp_page .tab_wrap .tab_content_left {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 160px;
      background: #113b62;
      opacity: 0.9;
      border-radius: 20px 20px 0px 0px;
      padding-top: 30px; }
      .tab_cmp_page .tab_wrap .tab_content_left .tab_item {
        height: 80px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: white;
        opacity: 0.7;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer; }
        .tab_cmp_page .tab_wrap .tab_content_left .tab_item .tab_item_content {
          position: relative; }
          .tab_cmp_page .tab_wrap .tab_content_left .tab_item .tab_item_content .tab_item_line {
            position: absolute;
            top: 30px;
            left: 50%;
            transform: translateX(-50%);
            width: 20px;
            height: 3px;
            background: #348ef4;
            border-radius: 2px; }
    .tab_cmp_page .tab_wrap .tab_content_right {
      position: absolute;
      top: 54px;
      right: 0;
      left: 220px;
      bottom: 0;
      line-height: 1; }
      .tab_cmp_page .tab_wrap .tab_content_right .title_wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: white; }
        .tab_cmp_page .tab_wrap .tab_content_right .title_wrap .btn_wrap {
          width: 98px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          background: linear-gradient(90deg, #0193de 0%, #0aceda 100%);
          border-radius: 16px;
          font-size: 16px;
          color: white;
          opacity: 0.9; }
      .tab_cmp_page .tab_wrap .tab_content_right .industry_status_wrap .tab_subtitle {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        color: white;
        opacity: 0.7;
        padding: 36px 0 12px 0; }
      .tab_cmp_page .tab_wrap .tab_content_right .industry_status_wrap .industry_desc {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        color: white;
        line-height: 32px;
        opacity: 0.7; }
      .tab_cmp_page .tab_wrap .tab_content_right .industry_pain_point_wrap {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        color: white;
        opacity: 0.7;
        margin-bottom: 70px; }
        .tab_cmp_page .tab_wrap .tab_content_right .industry_pain_point_wrap .tab_subtitle {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 300;
          color: white;
          opacity: 0.7;
          padding: 30px 0 20px 0; }
        .tab_cmp_page .tab_wrap .tab_content_right .industry_pain_point_wrap .point_list {
          display: flex; }
          .tab_cmp_page .tab_wrap .tab_content_right .industry_pain_point_wrap .point_list .point_item {
            display: flex;
            align-items: center;
            margin-right: 60px; }
      .tab_cmp_page .tab_wrap .tab_content_right .industry_icon_list {
        display: flex; }
        .tab_cmp_page .tab_wrap .tab_content_right .industry_icon_list .industry_icon_item {
          width: 100px;
          height: 158px;
          display: flex;
          flex-direction: column; }
          .tab_cmp_page .tab_wrap .tab_content_right .industry_icon_list .industry_icon_item .icon_word {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: white;
            opacity: 0.7;
            text-align: center;
            padding-top: 20px; }

