.tab_cmp_page {
  height: 100%;
  background-size: cover;
  background-position: center center;
  /* background-position: center; */
  background-repeat: no-repeat;
  // background: red;
  .tab_wrap {
    position: relative;
    width: 1180px;
    height: 100%;
    // background: yellow;
    margin: 0 auto;
    .tab_title {
      font-size:34px;
      font-family:Lantinghei SC;
      font-weight:600;
      color:rgba(255,255,255,1);
      line-height:49px;
      text-align: center;
      padding-top: 140px;
    }
    .tab_content {
      width: 1180px;
      position: absolute;
      top: 184px;
      left: 0;
      right: 0;
      bottom: 0;
      margin-top: 40px;
      // background: pink;
    }
    .tab_content_left {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width:160px;
      background:rgba(17,59,98,1);
      opacity:0.9;
      border-radius:20px 20px 0px 0px;

      padding-top: 30px;
      .tab_item {
        height: 80px;
        font-size:20px;
        font-family:Microsoft YaHei;
        font-weight:400;
        color:rgba(255,255,255,1);
        // line-height:49px;
        opacity:0.7;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .tab_item_content {
          position: relative;
          .tab_item_line {
            position: absolute;
            top: 30px;
            left: 50%;
            transform: translateX(-50%);
            width:20px;
            height:3px;
            background:rgba(52,142,244,1);
            border-radius:2px;
          }
        }
      }
    }
    .tab_content_right {
      position: absolute;
      top: 54px;
      right: 0;
      left: 220px;
      bottom: 0;
      // background: purple;
      line-height: 1;

      .title_wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size:24px;
        font-family:Microsoft YaHei;
        font-weight:400;
        color:rgba(255,255,255,1);
        // line-height:49px;
        .btn_wrap {
          width:98px;
          height:32px;
          line-height: 32px;
          text-align: center;
          background:linear-gradient(90deg,rgba(1,147,222,1) 0%,rgba(10,206,218,1) 100%);
          border-radius:16px;
          font-size:16px;
          color: white;
          opacity:0.9;
        }
      }
      .industry_status_wrap {
        .tab_subtitle {
          font-size:20px;
          font-family:Microsoft YaHei;
          font-weight:300;
          color:rgba(255,255,255,1);
          // line-height:32px;
          opacity:0.7;
          padding: 36px 0 12px 0;
        }
        .industry_desc {
          font-size:16px;
          font-family:Microsoft YaHei;
          font-weight:300;
          color:rgba(255,255,255,1);
          line-height:32px;
          opacity:0.7;
        }
  
      }
      .industry_pain_point_wrap {
        font-size:16px;
        font-family:Microsoft YaHei;
        font-weight:300;
        color:rgba(255,255,255,1);
        // line-height:32px;
        opacity:0.7;
        .tab_subtitle {
          font-size:20px;
          font-family:Microsoft YaHei;
          font-weight:300;
          color:rgba(255,255,255,1);
          // line-height:32px;
          opacity:0.7;
          padding: 30px 0 20px 0;
        }
        .point_list {
          display: flex;
          .point_item {
            display: flex;
            align-items: center;
            margin-right: 60px;
          }
        }
        margin-bottom: 70px;
      }
      .industry_icon_list {
        display: flex;
        .industry_icon_item {
          width: 100px;
          height: 158px;
          // background: red;
          display: flex;
          flex-direction: column;
          .icon_word {
            font-size:16px;
            font-family:Microsoft YaHei;
            font-weight:400;
            color:rgba(255,255,255,1);
            // line-height:49px;
            opacity:0.7;
            text-align: center;
            padding-top: 20px;
          }
        }
      }
    }
  }
}