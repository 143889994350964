.footer_com {
  .section1 {
    position: relative;
    .section1_img {
      width: 100%;
      min-width: 1366px;
    }
    .img_content {
      z-index: 99;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .img_content_word {
      font-size:48px;
      font-family:Lantinghei SC;
      font-weight:600;
      color:rgba(255,255,255,1);
      opacity:0.85;
      margin-bottom: 46px;
      text-align: center;
    }
    .img_content_tip {
      width: 728px;
      height: 84px;
      // background:rgba(7,82,94,1);
      background-image: url('../../image/component/footer/intro_process.png');
      border:1px solid rgba(21,101,114,1);
      border-radius:4px;
      font-size:24px;
      font-family:Microsoft YaHei;
      font-weight:400;
      color:rgba(255,255,255,0.7);
      margin-bottom: 54px;
    }
    .img_content_btn {
      // margin: 84px auto 0;
      display: inline-block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width:164px;
      height:44px;
      background:linear-gradient(90deg,rgba(36,212,165,1) 0%,rgba(14,184,174,1) 100%);
      opacity:0.9;
      border-radius:24px;
      font-size:20px;
      font-family:Microsoft YaHei;
      font-weight:400;
      color:rgba(254,254,254,1);
      text-align: center;
      line-height: 44px;
    }
  }
  .section2 {
    line-height: 1;
    margin-top: -1px;
    width: 100%;
    height: 420px;
    background-image: url('../../image/component/footer/foot_bg1.png');
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size:cover;
    -moz-background-size:cover;
    background-size:cover;
    padding-top: 62px;
    .footer_content {
      width: 1180px;
      height: 222px;
      display: flex;
      margin: 0 auto;
      font-size:16px;
      font-family:Microsoft YaHei;
      font-weight:300;
      color:rgba(255,255,255,0.4);
    }
    .footer_menu_title {
      padding: 0 0 22px;
    }
    .footer_menu_item {
      display: block;
      font-size:14px;
      margin-bottom: 20px;
      color:rgba(255,255,255,0.7);
      cursor: pointer;
    }
    .footer_menu_item2 {
      font-size:14px;
      margin-bottom: 20px;
      color:rgba(255,255,255,0.7);
    }
    .scane_code_panne {
      width: 147px;
      .img_wrap {
        width: 132px;
        height: 132px;
        background-image: url('../../image/component/footer/saoyisao.png');
        margin: 0 auto 26px;
      }
      .Code_wrap{
        width:104px;
        height:104px;
        margin: 14px;
      }
      .img_tip {
        font-size:14px;
        font-family:Source Han Sans CN;
        font-weight:400;
        color:rgba(173,176,179,1);
        // line-height:40px;
      }
    }
    .footer_componey_info {
      width: 1180px;
      height: 135px;
      margin: 0 auto;
      border-top: 1px solid rgba(255,255,255,0.1);

      font-size:16px;
      font-family:Microsoft YaHei;
      font-weight:400;
      color:rgba(255,255,255,0.2);
      line-height:94px;
      text-align: center;
    }
    .footer_link {
      color: rgba(255,255,255,0.2);
    }
    .footer_link:hover {
      color: #348ef4;
    }
  }
}