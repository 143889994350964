.micro_operation_page {
  .banner_img {
    width: 100%;
    min-width: 1200px;
  }
  .section_1 {
    background:rgba(255,255,255,1);
    overflow: hidden;
    .section_bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background:linear-gradient(0deg,rgba(92,170,243,1) 0%,rgba(83,229,221,1) 100%);
      opacity:0;
      border-radius:8px;
    }
    .subtitle_2 {
      position: absolute;
      width: 100%;
      font-size:18px;
      top: 324px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 99;
      text-align: center;
    }
    .section_1_list {
      position: relative;
      width: 1180px;
      height: 821px;;
      margin: 0  auto 80px;
      font-size:28px;
      font-family:Microsoft YaHei;
      font-weight:400;
      color:rgba(255,255,255,1);
      .section_bg_1 {
        width: 580px;
        height: 341px;
        position: absolute;
        left: 0;
        top: 0;
        background-image: url('../../image/microOperation/bg1.png');
        .icon_1 {
          position: absolute;
          top: 84px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 99;
        }
        .title_1 {
          position: absolute;
          top: 230px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 99;
        }
        .subtitle_2 {
          top: 278px;
        }
      }
      .section_bg_1:hover .section_bg{
        opacity: 0.6;
      }
      .section_bg_2 {
        width: 580px;
        height: 461px;
        position: absolute;
        right: 0;
        top: 0;
        background-image: url('../../image/microOperation/bg2.png');
        .icon_2 {
          position: absolute;
          top: 120px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 99;
        }
        .title_2 {
          position: absolute;
          top: 276px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 99;
        }
        // .subtitle_2 {
        //   position: absolute;
        //   font-size:18px;
        //   top: 324px;
        //   left: 50%;
        //   transform: translateX(-50%);
        //   z-index: 99;
        // }
      }
      .section_bg_2:hover .section_bg{
        opacity: 0.6;
      }
      .section_bg_3 {
        width: 580px;
        height: 461px;
        position: absolute;
        left: 0;
        bottom: 0;
        background-image: url('../../image/microOperation/bg3.png');
        .icon_3 {
          position: absolute;
          top: 143px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 99;
        }
        .title_3 {
          position: absolute;
          top: 291px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 99;
        }
        .subtitle_2 {
          top: 339px;
        }
      }
      .section_bg_3:hover .section_bg{
        opacity: 0.6;
      }
      .section_bg_4 {
        width: 580px;
        height: 341px;
        position: absolute;
        right: 0;
        bottom: 0;
        background-image: url('../../image/microOperation/bg4.png');
        .icon_4 {
          position: absolute;
          top: 85px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 99;
        }
        .title_4 {
          position: absolute;
          top: 230px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 99;
        }
        .subtitle_2 {
          top: 278px;
        }
      }
      .section_bg_4:hover .section_bg{
        opacity: 0.6;
      }
    }
  }
  .section_1_title {
    min-width: 1132px;
    text-align: center;
    margin: 80px 0 60px;
    font-size:34px;
    font-family:Source Han Sans CN;
    font-weight:500;
    color:rgba(51,51,51,1);
  }
  .section_2 {

  }
  .section_3 {
    overflow: hidden;
    background: #FAFCFF;
    .img_wrap {
      width: 1181px;
      margin: 0 auto 90px;
    }
  }
  .section_4 {
    background: #FFFFFF;
    overflow: hidden;
    .section_4_list {
      width: 1180px;
      margin: 0 auto;
      display: flex;
      margin-bottom: 80px;
    }
  }
  .section_5 {
    overflow: hidden;
    background: #FAFCFF;
  .section_5_list{
     width: 1180px;
      margin: 0 auto;
      display: flex;
      margin-bottom: 48px;
      flex-wrap: wrap;
  }
}
.more-case{
width:140px;
height:48px;
text-align: center;
line-height: 48px;
margin: 0 auto;
background:linear-gradient(90deg,rgba(77,194,255,1) 0%,rgba(42,152,255,1) 100%);
border-radius:24px;
font-size:16px;
font-family:Microsoft YaHei;
font-weight:400;
color:rgba(255,255,255,1);
margin-bottom: 41px;
cursor: pointer;
}
}